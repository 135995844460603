<template>
  <div>

    <v-list
      two-line
      dense
    >
      <template v-if="datas.length>0">
        <template v-for="(item, index) in datas">
          <v-divider
            v-if="item.divider"
            :key="`${index}`"
            :inset="item.inset"
          ></v-divider>

          <v-list-item
            v-else
            :key="`${item.title}-${index}`"
          >
            <v-list-item-avatar>
              <v-img :src="item.avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle><span class='text--primary mr-1'>{{$t('app.checkinUserType'+item.type)}}</span>
                <v-icon small>mdi-clock-outline</v-icon>
                {{checkin ? $moment(item.time_in).format("H:mm:ss") : $moment(item.time_out).format("H:mm:ss")}}
                <!-- {{checkin ? $moment(item.time_in).fromNow() : $moment(item.time_out).fromNow()}} -->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title>{{$t('error.dataNotfound')}}</v-list-item-title>
        </v-list-item-content>

      </v-list-item>

    </v-list>
  </div>

</template>
<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  components: {},
  props: {
    datas: {
      type: Array,
      default: []
    },
    checkin: {
      type: Boolean,
      default: true
    }
  }
});
</script>